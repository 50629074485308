var render = function render(){var _vm=this,_c=_vm._self._c;return _c('g',[_vm._l((_vm.points),function(coordinate,index){return _c('circle',{directives:[{name:"show",rawName:"v-show",value:(coordinate.diameter),expression:"coordinate.diameter"}],key:index,ref:"pointHandle",refInFor:true,attrs:{"transform":_vm.transformAtt ? _vm.transformAtt : '',"stroke-width":_vm.config2d.pressureTip / 5,"r":((coordinate.diameter ? coordinate.diameter : 1.32) * _vm.config2d.pressureTip) / 2,"cx":(_vm.config2d.isUnitMm ? coordinate.x : coordinate.x / _vm.config2d.inchToMm) *
        _vm.config2d.stackupScale +
        (_vm.config2d.viewBox[0] * _vm.xMultiplier),"cy":(
        (_vm.config2d.isUnitMm
          ? coordinate.y
          : coordinate.y / _vm.config2d.inchToMm)) *
        _vm.config2d.stackupScale +
        (_vm.config2d.viewBox[1] * _vm.yMultiplier),"data-index":index,"data-pk":coordinate.pk,"index":index},on:{"mouseover":function($event){_vm.showTooltip({
        x:
          (_vm.config2d.isUnitMm
            ? coordinate.x
            : coordinate.x / _vm.config2d.inchToMm) *
            _vm.config2d.stackupScale +
          (_vm.config2d.viewBox[0] * _vm.xMultiplier),
        y:
          (_vm.config2d.pcbHeight -
            (_vm.config2d.isUnitMm
              ? coordinate.y
              : coordinate.y / _vm.config2d.inchToMm)) *
            _vm.config2d.stackupScale  +
          (_vm.config2d.viewBox[1] * _vm.yMultiplier) + 1500,
        z:
          (
            (_vm.config2d.isUnitMm
              ? coordinate.y
              : coordinate.y / _vm.config2d.inchToMm)) *
            _vm.config2d.stackupScale -
          (coordinate.diameter * _vm.config2d.pressureTip) / 2 +
          (_vm.config2d.viewBox[1] * _vm.yMultiplier),
        text: `${coordinate.ref} ${coordinate.net} [${index + 1}]`,
        text2: ` - x:${coordinate.x} y:${coordinate.y}`,
        text3: ` - ${coordinate.tip_name} tip`,
      })},"mouseout":_vm.hideTooltip}})}),_vm._l((_vm.points),function(coordinate,index){return _c('text',{directives:[{name:"show",rawName:"v-show",value:(!coordinate.diameter),expression:"!coordinate.diameter"}],key:`x-${index}`,ref:"xHandle",refInFor:true,attrs:{"transform":_vm.transformAtt ? _vm.transformAtt : '',"font-size":"50","font-weight":"bold","text-anchor":"middle","dominant-baseline":"middle","x":(_vm.config2d.isUnitMm ? coordinate.x : coordinate.x / _vm.config2d.inchToMm) *
        _vm.config2d.stackupScale +
        (_vm.config2d.viewBox[0] * _vm.xMultiplier),"y":(
        (_vm.config2d.isUnitMm
          ? coordinate.y
          : coordinate.y / _vm.config2d.inchToMm)) *
        _vm.config2d.stackupScale +
        (_vm.config2d.viewBox[1] * _vm.yMultiplier),"data-index":index,"data-pk":coordinate.pk,"index":index},on:{"mouseover":function($event){_vm.showTooltip({
        x:
          (_vm.config2d.isUnitMm
            ? coordinate.x
            : coordinate.x / _vm.config2d.inchToMm) *
            _vm.config2d.stackupScale +
          (_vm.config2d.viewBox[0] * _vm.xMultiplier),
        y:
          (_vm.config2d.pcbHeight -
            (_vm.config2d.isUnitMm
              ? coordinate.y
              : coordinate.y / _vm.config2d.inchToMm)) *
            _vm.config2d.stackupScale  +
          (_vm.config2d.viewBox[1] * _vm.yMultiplier) + 1500,
        z:
          (_vm.config2d.pcbHeight -
            (_vm.config2d.isUnitMm
              ? coordinate.y
              : coordinate.y / _vm.config2d.inchToMm)) *
            _vm.config2d.stackupScale -
          (coordinate.diameter * _vm.config2d.pressureTip) / 2 +
          (_vm.config2d.viewBox[1] * _vm.yMultiplier),
        text: `x: ${coordinate.x} y: ${coordinate.y}`,
        text2: (coordinate.net ? ` - Net ${coordinate.net}` : ''),
        text3: (coordinate.ref ? `, Ref ${coordinate.ref}` : ''),
      })},"mouseout":_vm.hideTooltip}},[_vm._v("X")])})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }