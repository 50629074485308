<template>
  <g>
    <circle
      v-for="(coordinate, index) in points"
      v-show="coordinate.diameter"
      ref="pointHandle"
      :transform="transformAtt ? transformAtt : ''"
      :stroke-width="config2d.pressureTip / 5"
      :key="index"
      :r="((coordinate.diameter ? coordinate.diameter : 1.32) * config2d.pressureTip) / 2"
      :cx="
        (config2d.isUnitMm ? coordinate.x : coordinate.x / config2d.inchToMm) *
          config2d.stackupScale +
          (config2d.viewBox[0] * xMultiplier)
      "
      :cy="
        (
          (config2d.isUnitMm
            ? coordinate.y
            : coordinate.y / config2d.inchToMm)) *
          config2d.stackupScale +
          (config2d.viewBox[1] * yMultiplier)
      "
      :data-index="index"
      :data-pk="coordinate.pk"
      :index="index"
      @mouseover="
        showTooltip({
          x:
            (config2d.isUnitMm
              ? coordinate.x
              : coordinate.x / config2d.inchToMm) *
              config2d.stackupScale +
            (config2d.viewBox[0] * xMultiplier),
          y:
            (config2d.pcbHeight -
              (config2d.isUnitMm
                ? coordinate.y
                : coordinate.y / config2d.inchToMm)) *
              config2d.stackupScale  +
            (config2d.viewBox[1] * yMultiplier) + 1500,
          z:
            (
              (config2d.isUnitMm
                ? coordinate.y
                : coordinate.y / config2d.inchToMm)) *
              config2d.stackupScale -
            (coordinate.diameter * config2d.pressureTip) / 2 +
            (config2d.viewBox[1] * yMultiplier),
          text: `${coordinate.ref} ${coordinate.net} [${index + 1}]`,
          text2: ` - x:${coordinate.x} y:${coordinate.y}`,
          text3: ` - ${coordinate.tip_name} tip`,
        })
      "
      @mouseout="hideTooltip"
    />
    <text
      v-for="(coordinate, index) in points"
      v-show="!coordinate.diameter"
      ref="xHandle"
      :transform="transformAtt ? transformAtt : ''"
      :key="`x-${index}`"
      font-size="50"
      font-weight="bold"
      text-anchor="middle"
      dominant-baseline="middle"
      :x="
        (config2d.isUnitMm ? coordinate.x : coordinate.x / config2d.inchToMm) *
          config2d.stackupScale +
          (config2d.viewBox[0] * xMultiplier)
      "
      :y="
        (
          (config2d.isUnitMm
            ? coordinate.y
            : coordinate.y / config2d.inchToMm)) *
          config2d.stackupScale +
          (config2d.viewBox[1] * yMultiplier)
      "
      :data-index="index"
      :data-pk="coordinate.pk"
      :index="index"
      @mouseover="
        showTooltip({
          x:
            (config2d.isUnitMm
              ? coordinate.x
              : coordinate.x / config2d.inchToMm) *
              config2d.stackupScale +
            (config2d.viewBox[0] * xMultiplier),
          y:
            (config2d.pcbHeight -
              (config2d.isUnitMm
                ? coordinate.y
                : coordinate.y / config2d.inchToMm)) *
              config2d.stackupScale  +
            (config2d.viewBox[1] * yMultiplier) + 1500,
          z:
            (config2d.pcbHeight -
              (config2d.isUnitMm
                ? coordinate.y
                : coordinate.y / config2d.inchToMm)) *
              config2d.stackupScale -
            (coordinate.diameter * config2d.pressureTip) / 2 +
            (config2d.viewBox[1] * yMultiplier),
          text: `x: ${coordinate.x} y: ${coordinate.y}`,
          text2: (coordinate.net ? ` - Net ${coordinate.net}` : ''),
          text3: (coordinate.ref ? `, Ref ${coordinate.ref}` : ''),
        })
      "
      @mouseout="hideTooltip"
    >X</text>
  </g>
</template>
<style scoped>
.item {
  touch-action: none;
  user-select: none;
}
</style>
<script>
export default {
  name: "TestPointTop",
  props: {
    scale: {
      type: Number,
      default: null,
    },
    testpointPlot: {
      type: Array,
      default: null,
    },
    config2d: {
      type: Object,
      default: null,
    },
    xMultiplier: {
      type: Number,
      default: null,
    },
    yMultiplier: {
      type: Number,
      default: null,
    },
    transformAtt: {
      type: String,
      default: null,
    },
    gerberConfig: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      points: JSON.parse(JSON.stringify(this.testpointPlot)),
    };
  },
  methods: {
    activateDragAndDrop(calledFromRoot) {
      setTimeout(() => {
        if (calledFromRoot) {
          this.points = JSON.parse(JSON.stringify(this.testpointPlot));
        }
      }, 100);
    },
    showTooltip(payload) {
      this.$store.dispatch("ui/hoverText", `${payload.text}${payload.text2}${payload.text3}`);
      this.$emit("toggleHoverText", true);
    },
    hideTooltip() {
      this.$store.dispatch("ui/hoverText", null);
      this.$emit("toggleHoverText", false);
    },
  },
  mounted() {
    this.activateDragAndDrop(true);
  },
};
</script>
